import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// For Toastr
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//end for Toasrt
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthenticationService, OauthMobileService, OauthMainService, OauthAdminService, PagerService, Utilities, DataSharingService } from './_services/index';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MobileModule } from './mobile/mobile.module';
import { AdminInterceptor } from '../app/interceptors/admin.interceptor';

@NgModule({
  declarations: [
    AppComponent
    
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-center-center',
      preventDuplicates: true,
    }), // ToastrModule added
  ],
  
  
  providers: [
    AuthenticationService,
    OauthMobileService,
    OauthMainService,
    OauthAdminService,
    PagerService,
    Utilities,
    DataSharingService,
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: AdminInterceptor, multi: true } 
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
