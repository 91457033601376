export class Constants{
    public static ROLE_TYPES = {
        SUPER_ADMIN:1,
        ADMIN:1,
        MODRATOR:3,
        HOSPITAL_ADMIN:4,
        PATIENT_ADMIN:5
    }
    public static DEFAULT_DATA = {
        DEFAULT_ORGANIZATION:1,
        DEFAULT_LOCAL_ERROR_MSG:"Something wrong on server side.",
        DEFAULT_LOGIN_ERROR_MSG:"You are not authorized. Please login"
        
    }
    public static ADMIN_DATA = {
        LIMIT:20        
    }
    public static MAIN_DATA = {
        LIMIT:12        
    }

    //////////////////////// Amplitude /////////////////////////////
    public static KEY_HEADER_SEND_REQUEST_CLICK ="Header: User clicks on 'Send Request' ";
    public static HEADER_SEND_REQUEST_CLICK ="User clicks on 'Send Request' link of 'Header' view";

    public static KEY_SEARCH_PROCEDURE ="Search a Procedure";
    public static SEARCH_PROCEDURE ="User click on  'Search'  to search a procedure";
    
    public static KEY_PROCEDURE_LIST ="View: Procedure List";
    public static PROCEDURE_LIST ="List	User is on the 'Procedure Listing' view";

    public static KEY_LIST_PROCEDURE_SEND_REQUEST_CLICK ="Procedure List: User clicks on  'Send Request' ";
    public static LIST_PROCEDURE_SEND_REQUEST_CLICK ="User clicks on 'Send Request' of a 'procedure'  link of 'Procedure List' view";
    
    public static KEY_PROCEDURE_CLICK ="Procedure List: User clicks on a procedure";
    public static PROCEDURE_CLICK ="User clicks on a 'procedure'  link of 'Procedure List' view";

    public static KEY_PROCEDURE_VIEW ="View: Procedure Detail";
    public static PROCEDURE_VIEW ="User is on the 'Procedure Detail' view";

    public static KEY_PROCEDURE_REQUEST_CLICK ="Procedure Detail: User clicks on 'Request'";
    public static PROCEDURE_REQUEST_CLICK ="User clicks on the 'REQUEST' button  on the 'Procedure Detail' view";
    
    public static KEY_PROCEDURE_CANCEL_CLICK ="Procedure Detail: User clicks on 'Cancel'";
    public static PROCEDURE_CANCEL_CLICK ="User clicks on the 'CANCEL' button  on the 'Procedure Detail' view";
    
    ///////// REQUEST////////////////////
    public static KEY_SEND_REQUEST_VIEW ="View: Send Request";
    public static SEND_REQUEST_VIEW ="User is on the 'Send Request' of the 'Procedure Name' view";
    
    public static KEY_SEND_REQUEST_EDIT_VIEW ="Send Request: User Clicks on 'EDIT'";
    public static SEND_REQUEST_EDIT_VIEW ="User clicks on 'EDIT' button in 'Send Request' view";

    public static KEY_SEND_REQUEST_CLICK ="Send Request: User Clicks on 'SEND REQUEST'";
    public static SEND_REQUEST_CLICK ="User clicks on 'SEND REQUEST' button in 'Send Request' view";

    public static KEY_SEND_REQUEST_SUCCESS ="Successfully Request sent";	
    public static SEND_REQUEST_SUCCESS ="A user sent a request successfully for further processing";

    public static KEY_REQUEST_LIST_VIEW="View: Request Listing";
    public static REQUEST_LIST_VIEW ="User is on the 'Request Listing' view";

    public static KEY_REQUEST_LIST_REQUEST_CLICK ="Request Lisitng: User clicks on a request";
    public static REQUEST_LIST_REQUEST_CLICK ="User clicks on a 'request' link of 'Request List' view";

    public static KEY_REQUEST_VIEW ="View: Request Detail";
    public static REQUEST_VIEW ="User is on the 'Request Detail' view";

    public static KEY_SEND_FORM_REQUEST_EMAIL_VIEW ="View: Send Request Email Form";
    public static SEND_FORM_REQUEST_EMAIL_VIEW ="User is on the 'Send Request Treatment Email' of the 'Procedure Name' view";
    
    public static KEY_CONFIRM_FORM_REQUEST_EMAIL_VIEW ="View: Send Request Email Form Confirmation";
    public static CONFIRM_FORM_REQUEST_EMAIL_VIEW ="User is on the 'Send Request Treatment Email' Confirmation of the 'Procedure Name' view";
    
    ////////////////////// USER /////////////////////////////////
    public static KEY_REGISTER_CLICK ="Header: User clicks on Register link";
    public static REGISTER_CLICK ="User is on the 'Header' view and clicks on 'Register' link";

    public static KEY_REGISTER_VIEW ="View: Register User";	
    public static REGISTER_VIEW="User is on the 'Register' view";

    public static KEY_REGISTER_VIEW_SUCCESS ="Successfully create an accounT";
    public static REGISTER_VIEW_SUCCESS ="User successfully creates an account on the 'Register' view";

    public static KEY_LOGIN_VIEW ="View: Log in screen	User is on the 'Log in' screen";
    public static LOGIN_VIEW ="Successfully log in	User successfully logs in";

    public static KEY_LOGIN_VIEW_SUCCESS="Successfully log in";	
    public static LOGIN_VIEW_SUCCESS ="User successfully logs in";

    public static KEY_LOGOUT_VIEW ="Successfully log out";
    public static LOGOUT_VIEW ="User selects the 'logout' option and logs out successfully";

    public static KEY_HEADER_VIEW_PROFILE_CLICK ="Profile: Click to view my details";	
    public static HEADER_VIEW_PROFILE_CLICK ="User selects the 'Profile' option from the Account dropdown";

    public static KEY_PROFILE_VIEW ="View: User profile"
    public static PROFILE_VIEW ="User is on the 'Profile' view";

    public static KEY_EDIT_PROFILE_VIEW ="View: Edit Profile";
    public static EDIT_PROFILE_VIEW ="User is on the 'Edit Profile' view";

    public static KEY_EDIT_PROFILE_SUCCESS ="Profile: Edit Profile"
    public static EDIT_PROFILE_SUCCESS ="User successfully updates their account information"

    public static KEY_VERIFY_EMAIL_VIEW ="View:Verify User Email";
    public static VERIFY_EMAIL_VIEW ="By visiting this link from the user email will verify user email";

    public static KEY_LOGIN_VIEW_FORGOT_PASSWORD_CLICK ="Login View: User click on link 'Forgot Password'";
    public static LOGIN_VIEW_FORGOT_PASSWORD_CLICK ="On login view user will click on 'Forgot Password' link";

    public static KEY_FORGOT_PASSWORD_VIEW ="View: Forgot Password View";
    public static FORGOT_PASSWORD_VIEW ="user will view Forgot Password screen";

    public static KEY_FORGOT_PASSWORD_SUCCESS ="Forgot Password: User click on 'Save'";
    public static FORGOT_PASSWORD_SUCCESS ="On Forgot Password view user clickon 'Save' will successfully send forgot password email";

    public static KEY_RESET_PASSWORD_VIEW ="View: Reset password";
    public static RESET_PASSWORD_VIEW ="User will be redirected to Reset password screen from email";

    public static KEY_RESET_PASSWORD_VIEW_SUCCESS ="Successfully updated Password";
    public static RESET_PASSWORD_VIEW_SUCCESS ="User will enter new password and will updated successfully";
}