import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map';

import { AuthenticationService, OauthMainService, Utilities } from '../../../../_services/index';

@Component({
  selector: 'app-main-page-not-found',
  templateUrl: './main-page-not-found.component.html',
  styleUrls: ['./main-page-not-found.component.css']
})
export class MainPageNotFoundComponent implements OnInit {
  
  Httploading: boolean = false;
  post:any;
  loading: boolean = true;
  

  constructor( private authenticationService: AuthenticationService, private oauthMainService: OauthMainService, private router: Router, public util:Utilities) {
    
  }

  ngOnInit() {

  }



}
