import { Component } from '@angular/core';

import '../assets/admin/css/bootstrap.min.css'
import '../assets/admin/css/font-awesome.min.css'
import '../assets/admin/css/owl.carousel.min.css'
import '../assets/admin/css/style.css'
import '../assets/admin/css/responsive.css'
import '../assets/admin/css/toastr.css'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  private dynamicjaavscripts: any[];

  ngOnInit() {

    // this.dynamicjaavscripts = [
    //   'assets/admin/js/jquery-3.1.1.min.js'
    // ];
    // this.loadScript(this.dynamicjaavscripts); 
    // this.dynamicjaavscripts = [
    //   //'assets/admin/js/jquery-3.1.1.min.js',
    //   'assets/admin/js/bootstrap.min.js',
    //   'assets/admin/js/bootstrap-select.min.js',
    //   'assets/admin/js/swiper.min.js',
    //   'assets/admin/js/jquery.ajaxchimp.js',
    //   'assets/admin/js/jquery.countTo.js',
    //   'assets/admin/js/jquery.inview.min.js',
    //   'assets/admin/js/jquery.magnific-popup.min.js',
    //   'assets/admin/js/jquery-ui.min.js',
    //   'assets/admin/js/owl.carousel.min.js',
    //   'assets/admin/js/tinymce/tinymce.min.js',
    //   'assets/admin/js/isotope.min.js',
    //   'assets/admin/js/custom.js',
    //   'assets/admin/js/jquery.payform.min.js'
    // ];

    // this.loadScript(this.dynamicjaavscripts);           
  }
  
  ngAfterContentInit(){
    // this.dynamicjaavscripts = [
    //   'assets/admin/js/jquery-3.1.1.min.js'
    // ];
    // this.loadScript(this.dynamicjaavscripts); 
    // this.dynamicjaavscripts = [
    //   //'assets/admin/js/jquery-3.1.1.min.js',
    //   'assets/admin/js/bootstrap.min.js',
    //   'assets/admin/js/bootstrap-select.min.js',
    //   'assets/admin/js/swiper.min.js',
    //   'assets/admin/js/jquery.ajaxchimp.js',
    //   'assets/admin/js/jquery.countTo.js',
    //   'assets/admin/js/jquery.inview.min.js',
    //   'assets/admin/js/jquery.magnific-popup.min.js',
    //   'assets/admin/js/jquery-ui.min.js',
    //   'assets/admin/js/owl.carousel.min.js',
    //   // 'assets/admin/js/tinymce/tinymce.min.js',
    //   'assets/admin/js/isotope.min.js',
    //   'assets/admin/js/custom.js',
    //   'assets/admin/js/jquery.payform.min.js'
    // ];

    // this.loadScript(this.dynamicjaavscripts);           
  }

  loadScript(urlArr) {
    console.log("in loadscript");
  
  for (var i = 0; i < urlArr.length; i++){
    console.log("in loadscript i="+i);
    let node = document.createElement('script');
    node.src = urlArr[i];
    node.type = 'text/javascript';
    //document.getElementsByTagName('head')[0].appendChild(node);
    document.body.appendChild(node);
  }
}
}
