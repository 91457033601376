import { Injectable } from '@angular/core';
import { jwtDecode }  from 'jwt-decode';

interface MyToken {
    patientId: string;
    userId: number;
    email: string;
    first_name: string;
    last_name: string;
    exp: number;
    // whatever else is in the JWT.
  }

@Injectable()
export class OauthMobileService {
    
    constructor(){
    }

    isAuthenticated(){
        return localStorage.getItem('accessToken')?true:false;
    }

    getAccessToken() {
        return localStorage.getItem('accessToken');
    }

    getRefreshToken() {
       return localStorage.getItem('refreshToken');;
    }

    getMobile() {
        return  localStorage.getItem('mobile');;
    }

    getPatientId(){
        let decoded_access_token = jwtDecode<MyToken>(this.getAccessToken());
        return decoded_access_token.patientId;
    }

    ///////// set token data ///////////
    setAccessToken(accessToken) {
        localStorage.setItem('accessToken', accessToken);
    }

    setRefreshToken(refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
    }

    setMobile(mobile) {
        localStorage.setItem('mobile', mobile);
    }

    ///////////// remove token data ///////
    removeAccessToken() {
        localStorage.removeItem('accessToken');
    }

    removeMobile() {
        localStorage.removeItem('mobile');
    }

    removeRefreshToken() {
        localStorage.removeItem('refreshToken');
    }

    
}