import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataSharingService {
    public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isMainUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public setSendFormProcedureTitle: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public setSendFormProcedureId: BehaviorSubject<string> = new BehaviorSubject<string>("");
}
