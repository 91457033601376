import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, ValidatorFn} from '@angular/forms';
import { Observable, throwError, config } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../../app/utils/constants';
import amplitude  from 'amplitude-js'
import { appConfig } from '../config/config';

@Injectable()
export class Utilities {
    
    constructor(private router: Router, private toastr: ToastrService){

    }
    // redirect like add user, etc
    redirect(pagename: string) {
        this.router.navigate(['/'+pagename]);
    }

    // find an element in json array and then delete
    findAndRemove(array, property, value) {
        array.forEach(function(result, index) {
          if(result[property] === value) {
            //Remove from array
            array.splice(index, 1);
          }    
        });
    }
    // if number is less than 10 then append 0
    padDate(n: number) {
        return n<10 ? '0'+n : n;
    }

    processServerImage(imgurl, FormGPObJ, imgField){

        var request = new XMLHttpRequest();
        request.open('GET', imgurl, true);
        request.responseType = 'blob';
        var userForm: FormGroup
        userForm = FormGPObJ
        var imgFile: File
        request.onload = function() {
            var reader = new FileReader();
            reader.readAsDataURL(request.response);
            var imgFile = new File([request.response], imgurl);
            reader.onload = function(event) {
                userForm.get(imgField).setValue(imgFile);
                // The file's text will be printed here
                //console.log(event.target);
            };
        };
        request.send();
    
    }
    toInt(strNumber:string){
        return parseInt(strNumber);
    }

    handleError(error: any) {
        console.log("util admin auth error handing")
       let errorMessage = '';
      //  if (error.error instanceof ErrorEvent) {
      //    // Get client-side error
      //    errorMessage = error.error.message;
      //    console.log("util admin auth error handing error.error.message"+error.error.message);
      //  } else {
      //    // Get server-side error
      //   //  errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      //     if (error.error){
      //       errorMessage = error.error.message;
      //     }else{
      //       errorMessage = error.message;
      //     }
        
      //   console.log("util admin auth error handing error.error.message ="+errorMessage);
      //  }
      errorMessage=this.getLocalErrorMessage(error);
       this.showToast("API Error ", errorMessage,"error");
       return throwError(() => {
         return errorMessage;
       });
     }

     getLocalErrorMessage(error:any){
       let errorMsg ='';
        errorMsg = this.parseErrors(error).message;

        return errorMsg;
     }

     showToast(title, message, type, config={
      timeOut: 3000,
      closeButton: false
    }) {
      switch(type){
        case 'success':
          this.toastr.success(title, message, config);
          break;
        case 'error':
          this.toastr.error(title, message, config);
        break;
        case 'info':
          this.toastr.info(title, message, config);
        break;
        case 'warning':
          this.toastr.warning(title, message, config);
        break;
        default:
          this.toastr.success(title, message, config);
          break;
      }
      
    }

    getPasswordRegex(){
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d#?!@$%^&*_-]{8,20}$/;
    }

    getEmailRegex(){
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    }

    parseErrors = (errObj) => {
      try {
        let message = "";
        const { errors } = errObj.error;
        switch (errObj.status) {
          case 400:
            if (errors){
              errors.map((obj, index) => {
                message = `${message + obj.param.toUpperCase()}: ${obj.msg}`;
                message = index === errors.length - 1 ? message : `${message} ,`;
                return message;
              });
            }else{
              message=
                errObj.data && errObj.data.message
                  ? errObj.data.message
                  : Constants.DEFAULT_DATA.DEFAULT_LOCAL_ERROR_MSG;
            }
            return {
              success: false,
              message,
            };
          break;
          case 401:
            return {
              success: false,
              message:
                errObj.data && errObj.data.message
                  ? errObj.data.message
                  : Constants.DEFAULT_DATA.DEFAULT_LOGIN_ERROR_MSG,
            };
            break;
          case 403:
            return {
              success: false,
              message: errObj.error
                ? errObj.error.message
                : errObj.message
                ? errObj.message
                : Constants.DEFAULT_DATA.DEFAULT_LOCAL_ERROR_MSG,
            };
            break;
          case 404:
            return {
              success: false,
              message: errObj.error
                ? errObj.error.message
                : errObj.message
                ? errObj.message
                : Constants.DEFAULT_DATA.DEFAULT_LOCAL_ERROR_MSG,
            };
            break;
          case 409:
            return {
              success: false,
              message: errObj.error
                ? errObj.error.message
                : errObj.message
                ? errObj.message
                : Constants.DEFAULT_DATA.DEFAULT_LOCAL_ERROR_MSG,
            };
            break;
          case 422:
            return {
              success: false,
              message: errObj.data
                ? errObj.data.message
                : errObj.message
                ? errObj.message
                : Constants.DEFAULT_DATA.DEFAULT_LOCAL_ERROR_MSG,
            };
            break;
          default:
            return {
              success: false,
              message: Constants.DEFAULT_DATA.DEFAULT_LOCAL_ERROR_MSG,
            };
        }
      } catch (error) {
        return {
          success: false,
          message: Constants.DEFAULT_DATA.DEFAULT_LOCAL_ERROR_MSG,
        };
      }
    };

    MustMatch(controlName: string, matchingControlName: string): ValidatorFn {
      return (formGroup: FormGroup) => {
          const control = formGroup.controls[controlName];
          const matchingControl = formGroup.controls[matchingControlName];
      

          if (matchingControl.errors && !matchingControl.errors.mustMatch) {
              // return if another validator has already found an error on the matchingControl
              return null;
          }

          // set error on matchingControl if validation fails
          if (control.value !== matchingControl.value) {
              return { mustMatch: true };
          } else {
              return null;
          }
      }
  }

  /////////////////// Amplitude ////////////////////
   initAmplitude(){
    amplitude.getInstance().init(appConfig.amplitude.APIKey);
   }

   sendAmplitudeData(eventname:string, eventDescription: string | object){
     if (typeof eventDescription === 'string'){
       eventDescription={ eventDescription:eventDescription };
     }
    amplitude.getInstance().logEvent(eventname, eventDescription);
   }

   setAmplitudeUserId(userId:string){
    amplitude.getInstance().setUserId(userId);
   }
   
   resetAmplitudeUserId(){
    amplitude.getInstance().setUserId(null);
   }
   
   setAmplitudeUserProperties(properties) {
    amplitude.getInstance().setUserProperties(properties);
   }
  
   setAmplitudeUserDevice(installationToken){
    amplitude.getInstance().setDeviceId(installationToken);
   }
}