import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { OauthMobileService } from './oauthmobile.service';
import { OauthMainService } from './oauthmain.service';
import { OauthAdminService } from './oauthadmin.service';

@Injectable()
export class AuthenticationService {

    options = {
        headers: new HttpHeaders({
        }),
      };
    
    constructor(private http: HttpClient, private oauthMobileService: OauthMobileService, private oauthAdminService: OauthAdminService, private oauthMainService: OauthMainService) {
        
    }

    login(email: string, password: string): Observable<any> {
      console.log("login");
      var loginFormData = new FormData();

      loginFormData.append('email', email);
      loginFormData.append('password', password);

        return this.http.post('user/auth/login', loginFormData,  this.options);
    }
    
    adminLogin(email: string, password: string): Observable<any> {
      console.log("adminlogin");
      var loginFormData = new FormData();

      loginFormData.append('email', email);
      loginFormData.append('password', password); 

        return this.http.post('user/auth/login', loginFormData,  this.options);
    }

    patientLogin(loginFormData): Observable<any> {
        return this.http.post('patient/auth/login', loginFormData,  this.options);
    }

    patientForgotPassword(loginFormData): Observable<any> {    
        return this.http.post('patient/auth/forgotpassword', loginFormData,  this.options);
    }

    patientForgotPasswordVerify(loginFormData): Observable<any> {    
      return this.http.post('patient/auth/forgotpasswordverify', loginFormData,  this.options);
    }

    patientForgotPasswordReset(loginFormData): Observable<any> {    
      return this.http.post('patient/auth/forgotresetpassword', loginFormData,  this.options);
    }
    mainLogout(): void {
        // clear token remove user from local storage to log user out
        this.oauthMainService.removeAccessToken();
        this.oauthMainService.removeRefreshToken();
        this.oauthMainService.removeEmail();
        this.oauthMainService.removeUserName();
    }

    mobileLogout(): void {
        // clear token remove user from local storage to log user out
        this.oauthMobileService.removeAccessToken();
        this.oauthMobileService.removeRefreshToken();
        this.oauthMobileService.removeMobile();
    }

    adminLogout(): void {
        // clear token remove user from local storage to log user out
        this.oauthAdminService.removeAccessToken();
        this.oauthAdminService.removeRefreshToken();
        this.oauthAdminService.removeEmail();
        this.oauthAdminService.removeUserName();
        //window.location.reload();
    }

}