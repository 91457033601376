import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

interface MyToken {
    userId: string;
    email: string;
    first_name: string;
    last_name: string;
    exp: number;
    // whatever else is in the JWT.
  }

@Injectable()
export class OauthAdminService {
    
    constructor(){
    }

    isAuthenticated(){
        return localStorage.getItem('accessAdminToken')?true:false;
    }
    
    ///////// get token data ///////////
    getAccessToken() {
        return localStorage.getItem('accessAdminToken');
    }

    getRefreshToken() {
       return localStorage.getItem('refreshAdminToken');;
    }

    getEmail() {
        return  localStorage.getItem('adminEmail');;
    }

    getUserName() {
        return  localStorage.getItem('adminUserName');;
    }

    getUserId(){
        let decoded_access_token = jwtDecode<MyToken>(this.getAccessToken());
        console.log("decoded_access_token"+JSON.stringify(decoded_access_token));
        return decoded_access_token.userId;
    }

    ///////// set token data ///////////
    setAccessToken(accessToken) {
        localStorage.setItem('accessAdminToken', accessToken);
    }

    setRefreshToken(refreshToken) {
        localStorage.setItem('refreshAdminToken', refreshToken);
    }

    setEmail() {
        let decoded_access_token = jwtDecode<MyToken>(this.getAccessToken());
        var userEmail = decoded_access_token.email;
        localStorage.setItem('adminEmail', userEmail);
    }

    setUserName(userName="") {
        console.log("setUserName"+userName);
        let decoded_access_token = jwtDecode<MyToken>(this.getAccessToken());
        var fullUserName = decoded_access_token?.first_name + " "+decoded_access_token?.last_name;
        console.log("setUserName decoded_access_token.first_name"+decoded_access_token.first_name);
        console.log("setUserName decoded_access_token.last_name"+decoded_access_token.last_name);
        var response = userName!==""?userName:fullUserName;
        console.log("setUserName"+response);
        localStorage.setItem('adminUserName', response);
        return response;
    }

    ///////////// remove token data ///////
    removeAccessToken() {
        localStorage.removeItem('accessAdminToken');
    }

    removeEmail() {
        localStorage.removeItem('adminEmail');
    }
    
    removeUserName() {
        localStorage.removeItem('adminUserName');
    }

    removeRefreshToken() {
        localStorage.removeItem('refreshAdminToken');
    }

    
}