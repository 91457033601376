<!-- ===== Start of Login - Register Section ===== -->
<section class="ptb80">
  <div class="container">
      <div class="col-md-12 col-xs-12 label_height">
          <form>
      <h3> Page Not Found</h3>
          <!-- Start of Login Form -->
          <!-- Form Group -->
         
          <div class="form-group mt40">
                            
          </div>
          <div class="form-group">
              <label class="col-md-8 col-sm-8 col-xs-8 text-right">We couldn't find that page! Not even with x-ray vision.</label>
             
          </div>

          <!-- Form Group -->
          <div class="form-group text-center mt20">
              <!--<input type="submit" class="btn btn-blue btn-effect" value="Login" [disabled]="!loginForm.valid">-->
              <input class="btn btn-blue btn-effect" (click)="util.redirect('/')" value="Procedures" type="button">
          </div>

        
        <!-- End of Login Form -->
          </form>
      </div>
      <!--
      <div class="col-md-2 col-xs-2 col-xs-12">
              <img class="img-responsive" src="/assets/admin/images/120X600.jpg" alt="Banner image">
          </div>
        -->
  </div>
</section>
<!-- ===== End of Login - Register Section ===== -->
