import { Injectable } from '@angular/core';
import { jwtDecode }  from 'jwt-decode';

interface MyToken {
    patientId: string;
    userId: string;
    email: string;
    first_name: string;
    last_name: string;
    exp: number;
    // whatever else is in the JWT.
  }

@Injectable()
export class OauthMainService {
    
    constructor(){
    }

    isAuthenticated(){
        return localStorage.getItem('accessMainToken')?true:false;
    }

    getAccessToken() {
        return localStorage.getItem('accessMainToken');
    }

    getRefreshToken() {
       return localStorage.getItem('refreshMainToken');;
    }

    getEmail() {
        return  localStorage.getItem('email');;
    }

    getUserName() {
        return  localStorage.getItem('mainUserName');;
    }

    getUserId(){
        let decoded_access_token = jwtDecode<MyToken>(this.getAccessToken());
        return decoded_access_token.patientId;
    }

    ///////// set token data ///////////
    setAccessToken(accessToken) {
        localStorage.setItem('accessMainToken', accessToken);
    }

    setRefreshToken(refreshToken) {
        localStorage.setItem('refreshMainToken', refreshToken);
    }

    setEmail() {
        let decoded_access_token = jwtDecode<MyToken>(this.getAccessToken());
        var userEmail = decoded_access_token.email;
        localStorage.setItem('email', userEmail);
    }

    setUserName(userName="") {
        let decoded_access_token = jwtDecode<MyToken>(this.getAccessToken());
        var fullUserName = decoded_access_token.first_name + " "+decoded_access_token.last_name;
        var response = userName!==""?userName:fullUserName;
        localStorage.setItem('mainUserName', response);
        return response;
    }
    ///////////// remove token data ///////
    removeAccessToken() {
        localStorage.removeItem('accessMainToken');
    }

    removeEmail() {
        localStorage.removeItem('email');
    }
    removeUserName() {
        localStorage.removeItem('mainUserName');
    }
    removeRefreshToken() {
        localStorage.removeItem('refreshMainToken');
    }

    
}