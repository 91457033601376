import { environment } from '../../environments/environment';
let httpURL=environment.httpURL;
let amplitudeKey= environment.amplitudeKey;
export const appConfig = {
    'httpURL':httpURL!=null?httpURL:'http://localhost:3000',
    "paymentAPI":{
      "sellerId":"901384149",
      "publishableKey":"48350C5D-C237-4698-BC95-736420B00AE9"
    },
    "smsAPI":{
      "key":"O3HXDsT0QGybjqSA2pYMbw==",
      "url":"https://platform.clickatell.com/messages"
    },
    "apiDefaults":{
      "timeOut":10000    
    },
    "platform":{
      "android":{
        "key":'O3HXDsT0QGybjqSA2pYMbw==1'   
      },
      "web":{
        "key":'O3HXDsT0QGybjqSA2pYMbw=='   
      }       
    },
    "default":{
      "logo":{
        "url":'https://s3.us-east-2.amazonaws.com/cdnsj.mediktour.com/images/new_mediktour_logo.png'   
      },
      "procedure_image":{
        "url":'https://s3.us-east-2.amazonaws.com/cdn.mediktour.com/images/default/default_procedure_image.jpg'   
      }       
    },
    "amplitude":{
      "APIKey": amplitudeKey    
    } 

  }