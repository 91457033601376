import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHeaders, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Router,NavigationEnd  } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, timeout, retry } from 'rxjs/operators';
import { OauthAdminService, OauthMainService, Utilities } from '../_services'
import { environment } from '../../environments/environment';
import { appConfig } from '../config/config';
@Injectable()
export class AdminInterceptor implements HttpInterceptor {
    constructor(private oauthAdminService: OauthAdminService, private oauthMainService: OauthMainService, private util: Utilities, private router: Router) { }
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(httpRequest.method=="PUT" || httpRequest.method=="POST"){
        httpRequest = httpRequest.clone({
          setHeaders: { 
            'x-api-key': appConfig.platform.web.key
        }
      });
    }else{
      httpRequest = httpRequest.clone({
        setHeaders: { 
          'Content-Type': 'application/json',
          'x-api-key': appConfig.platform.web.key
       }
    });
    }
    if (this.router.url.startsWith('/admin'))
    {
        if (this.oauthAdminService.isAuthenticated()){
          httpRequest = httpRequest.clone({
            setHeaders: { 
              Authorization: `Bearer ${this.oauthAdminService.getAccessToken()}`
          }
        });
        }
    }else {
      if (this.oauthMainService.isAuthenticated()){
        httpRequest = httpRequest.clone({
          setHeaders: { 
            Authorization: `Bearer ${this.oauthMainService.getAccessToken()}`
        }
      });
      }

    }

    httpRequest = httpRequest.clone({ url: appConfig.httpURL+'/'+httpRequest.url });

    return next.handle(httpRequest).pipe(
      timeout(appConfig.apiDefaults.timeOut), 
      retry(1),
      tap(event => {
	          if (event instanceof HttpResponse) {
	            //  console.log("httpRequest url"+httpRequest.url);
              // console.log(" all looks good");
              // console.log(" all looks good event.body"+JSON.stringify(event.body));
              // console.log(" all looks good event.body.data"+event.body.data);
              let accessToken = event?.body?.data?.accessToken;
              let refreshToken = event?.body?.data?.refreshToken;
              // http response status code
              if(event.status == 400){
                return event;
              }else {
                if (accessToken) {
                   if (httpRequest.url.endsWith('/user/auth/login'))
                   {
                    this.oauthAdminService.setAccessToken(accessToken);
                    this.oauthAdminService.setRefreshToken(refreshToken);
                    this.oauthAdminService.setUserName();
                    this.oauthAdminService.setEmail();
                   }else{
                     this.oauthMainService.setAccessToken(accessToken);
                     this.oauthMainService.setRefreshToken(refreshToken);
                     this.oauthMainService.setUserName();
                     this.oauthMainService.setEmail();
                   }
                }
                return event;
              }
	          }
	        }, error => {
	   			// http response status code
              // console.log("----response----");
              // console.log("----response---- data"+JSON.stringify(error));
	          	// console.error("status code:");
	          	// console.error(error.status);
	          	// console.error(error.message);
              // console.log("--- end of response---");
              this.util.handleError(error);

	        })
    );
  }

 

}